import moment from "moment";
import 'moment/locale/fr';
import { env } from "../configs/EnvironmentConfig";

export const convertDate = (date: any, format = "DD-MM-YYYY") => {
    return moment(date).format(format);
}

export function getFilePath(file: string|undefined) {
    if (file)
        if (file.startsWith('http') && file.includes(':')) {
            return file.replace('//file', '/file');
        } else {
            return `${env?.API_ENDPOINT_URL}/${file}`.replace('//file', '/file')
        }
}

export const activityTypes = [
    {label: 'Activités communes', value: 'GROUP'},
    {label: 'Activités de groupes', value: 'COMMON'},
    {label: 'Activités individuelles', value: 'INDIVIDUAL'}
];

export const sessionTypes = [
    {label: 'Formation', value: 'TRAINING'},
    {label: 'Evaluation', value: 'EVALUATION'},
];

export const sessionNatures = [
  {label: 'Activité plannifiée', value: 'SCHEDULED'},
  {label: 'Activité réalisée', value: 'DONE'},
];

export const booleanResponse = [
  {label: 'Oui', value: true},
  {label: 'Non', value: false},
];

export const getActivityTypeLabel = (value: string) => {
    return activityTypes.find(at => at.value === value)?.label ?? value;
}

export const getSessionTypeLabel = (value: string) => {
  return sessionTypes.find(at => at.value === value)?.label ?? value;
}

export const getSessionNatureLabel = (value: string) => {
  return sessionNatures.find(at => at.value === value)?.label ?? value;
}

export const getBooleanLabel = (value: string) => {
  return booleanResponse.find(at => at.value)?.label ?? value;
}

export const addParamsToUrl = (uri: string, params: any) => {
    const origin = 'http://localhost';
    let url = new URL(uri, origin);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        url.searchParams.set(key, params[key]);
      }
    }
  
    return url.toString().split(origin)[1];
}

export const getYearArray = () => {
    const currentYear = new Date().getFullYear();
    const yearsBefore = 3;
    const yearsAfter = 6;
    const yearArray = Array.from({ length: yearsBefore + yearsAfter + 1 }, (_, index) => currentYear - yearsBefore + index);
    return yearArray;
  }

export const getDaysOfWeekArray = (startDate: any, endDate: any) => {
    moment.locale('fr');
    startDate = moment(startDate);
    endDate = moment(endDate);

    const result = [];
    let currentDate = moment(startDate);
  
    while (currentDate.isSameOrBefore(endDate)) {
      const dayOfWeek = currentDate.format('dddd');
      result.push({label: capitalize(dayOfWeek)+" le "+currentDate.format('DD MMM YYYY'), date: currentDate.format('YYYY-MM-DD')});
      currentDate.add(1, 'day');
    }
  
    return result;
  }

export const capitalize = (str: string) => {
    if (typeof str !== 'string' || str.length === 0) {
      return str;
    }
  
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const cleanDatas = (data: any) => {
  for (let key in data) {
    if (data[key] === undefined || data[key] === null) {
      delete data[key];
    }
  }
  return data;
}
